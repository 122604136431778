body {
    background-color: #f2f2f2;
  }
  .top{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: -20px;
  }
  .top p{
    margin-left: 15%;
  }
  .buttons{
    display: flex;
    justify-content:center;
    margin-top: 10px;
    margin-right: 15%;
  }
  .container {
    width: 80%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .button{
    padding: 5px 10px 15px 10px;
    
    background-color: #49243E;
    border: #49243E;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    align-self: center;
    margin-left: 5px;
    margin-top: -20px;
    
    
}
  
  .header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .header h1 {
    font-size: 24px;
    padding-top: 20px;
  }
  .header h2{
    font-size: 24px; 
  }
  
  .receipt-details {
    font-size: 20px;
  }
  .receipt-details ul {
    list-style-type: none;
    padding-left: 0;
  }
  .receipt-details li{
    font-weight: 500;
  }
  
  .table {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .table-header {
    background-color: #f0f0f0;
    font-weight: bold;
  }
  
  .table-body {
    background-color: #f9f9f9;
  }
  
  .table-footer {
    background-color: #f0f0f0;
    font-weight: bold;
  }
  
  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ccc;
  }
  
  .policies {
    font-weight: 500;
  }
  .policies h2 {
    font-size: 18px;
    font-weight: bold;
  }
  
  h3 {
    margin-top: 10px;
    font-size: 24px;
  }
  
  .policies ul {
    list-style-type:disc;
    padding-left: 20px;
  }

  .policies ol{
    list-style-type: decimal;
    padding-left: 20px;
    font-size: 11px;
  }
  
  .policies li {
    font-size: 11px;
    font-weight: bold;
  }
  p{
    color:black;
    font-size: 11px;
  }
  
  .footer {
    text-align: center;
    font-size: 12px;
    margin-top: 20px;
    padding: 10px;
  }


  @media screen and (max-width: 600px) {
    .table {
      width: 100%;
      margin-bottom: 20px;
    }
  
    th, td {
      padding: 8px;
      text-align: left;
      border-bottom: 1px solid #ccc;
      display: block;
      width: 100%;
    }
  
    .table-header th,
    .table-body td,
    .table-footer th {
      font-size: 14px;
    }
    .top {
      flex-direction: column;
      align-items: center;
    }
  
    .top p {
      margin-left: 0;
      margin-bottom: 10px;
    }
  
    .buttons {
      flex-direction: column;
      margin-left: 15%;
    }
  
    .button {
      margin-top: -20px;
    }

  }