.back {
    width: 100%;
    height: 100vh;
    background-color: #ecf0f1;
    display: grid;
    justify-content: center;
}

.back_form {
    width: 35vw;
    height: 60vh;
    background: white;
    box-shadow: 10px 10px 10px gray;
    border-radius: 10px;
    justify-content: center;
    margin-top: 50px;
}

.back_form .back_img_form{
    display: flex;
    justify-content: center;
}

.back_form .back_img_form img{
    width: 170px;
    height: 170px;
}

.back_form .back_text_form{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 40px 40px 40px;
}

.back_text_form h1 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}
.back_text_form h3 {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}
.back_text_form p {
    font-size: 12px;
    color: #666;
    margin-bottom: 20px;
}

.form{
    display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.button_back{
    width: 100%;
    margin-top: 40px;
    margin-bottom: 5px;
    background-color: #49243E;
    border: #49243E;
    border-radius: 10px;
    /* height: 60%; */
    color: white;
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
}

.button_back:hover{
    background-color: #704264;
    border: #704264;
}

@media screen and (max-width: 768px) {
    .back_form {
        width: 80vw;
        height: 60vh;
    }

    .button_back{
        margin-left: 50px;
    }
}

@media screen and (min-width: 1440px) {
    .back_form {
        width: 50vw;
        height: 70vh;
    }
}