.privacy {
    width: 100%;
    background-color: rgb(212, 209, 209);
    display: grid;
    justify-content: center;
}

.privacy_form {
    padding: 40px;
    width: 50vw;
    background-color: white;
    margin-top: 20px;
}

.privacy_form p{
    color: black;
    font-weight: 400;
}

.privacy_form h1{
    color: black;
    font-weight: 400;
    font-size: xx-large;
}
