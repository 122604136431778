.confirm {
    width: 100%;
    background-color: #ecf0f1;
    display: grid;
    justify-content: center;
    height: fit-content;
}

.confirm_form {
    display: flex;
    flex-direction: column;
    width: 35vw;
    background: white;
    box-shadow: 10px 10px 10px gray;
    border-radius: 10px;
    justify-content: center;
    margin-top: 20px;
}

.confirm_form .confirm_img_form{
   
    display: flex;
    justify-content: center;
}

.confirm_form .confirm_img_form img{
    width: 170px;
    height: 170px;
}

.confirm_form .confirm_text_form{
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 4px 40px 0px 40px;
}

.confirm_form .confirm_text_form h1{
    text-align: center;
    font-size: 25px;
    font-weight: 600;
}

Label{
    font-weight: bold;
}

.button{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 40px;
    background-color: #49243E;
    border: #49243E;
    border-radius: 10px;
    height: 60%;
    color: white;
    font-weight: bold;
}

p a{
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: rgb(16, 16, 83);
}

p{
    font-weight: bold;
}

.button:hover{
    background-color: #704264;
    border: #704264;
}

.input {
    height: 40px;
    border-radius: 10px;
    border: #ecf0f1;
}

.confirm_form .privacy{
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
    background: white;
}
@media screen and (max-width: 768px) {
    .confirm {
        width: 100%;
        height: 220vh;
        background-color: #F1E5D1;
        display: grid;
        justify-content: center;
    }
    
    .confirm_form {
        width: 80vw;
        height: 180vh;
    }

}