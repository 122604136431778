.home {
    width: 100%;
    height: 100vh;
    background-color: #ecf0f1;
    display: grid;
    justify-content: center;
}

.home_form {
    margin-top: 30px;
    display: grid;
    flex-direction: column;
    width: 35vw;
    height:fit-content;
    background: white;
    box-shadow: 10px 10px 10px gray;
    border-radius: 10px;
    justify-content: center;
}

.home_form .img_form{
    display: flex;
    justify-content: center;
}

.home_form .img_form img{
    width: 170px;
    height: 170px;
}

.home_form .form{
    /* justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 0px 40px 40px 40px; */
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 4px 40px 0px 40px;
    align-items:normal;
    text-align:left;
}

.home_form .form h1{
    text-align: center;
    font-size: 25px;
    font-weight: 600;
}

Label{
    font-weight: bold;
}

.home_button{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 40px;
    background-color: #49243E;
    border: #49243E;
    border-radius: 10px;
    /* height: 60%; */
    color: white;
    font-weight: bold;
    padding: 10px;
}

p a{
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: rgb(16, 16, 83);
}

p{
    font-weight: bold;
}

.button:hover{
    background-color: #704264;
    border: #704264;
}

.input {
    height: 10%;
    border-radius: 10px;
    border: white;
    padding: 20px;
    background-color: #ecf0f1;
}

@media screen and (max-width: 768px) {
    .home {
        width: 100%;
        height: 120vh;
        background-color: #F1E5D1;
        display: grid;
        justify-content: center;
    }
    
    .home_form {
        width: 80vw;
        height: 110vh;
    }

    .home_form .form h1{
        text-align: center;
        font-size: 25px;
        font-weight: 600;
    }

}


